import { Card, Row, Col, Button } from "react-bootstrap";
import { useAccordion } from "../../contexts/AccordionContext";
import { getSection } from "../../utils/SchemaUtils";
import { sectionStyles } from "../../styles/EditDataStyles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { LuTrash2, LuPlus } from "react-icons/lu";

const WEZSection = ({schema, ignoreSource, handleAddSchemaClick}) => {
    const { isDeleting, handleDelete } = useAccordion();

    const wezInfoText = (data) => {
        let parseVal;
        try {
            parseVal = JSON.parse(data.Value); // Attempt to parse the JSON string
        } catch (error) {
            parseVal={};
        }
        const keys = Object.keys(parseVal);
        return(
            keys.map((key) => `${key ? key + " => " + parseVal[key] + "\n" : ""}`)
        );
    };

    return (<>
        <Row className="mb-4" style={sectionStyles.header.title}>
            <Col>WEZ Info</Col>
            <Col className="float-end text-end">
                <Button
                    style={{ textWrap: "nowrap" }}
                    variant="warning"
                    onClick={() => { handleAddSchemaClick(null, "WEZ"); }}
                    data-testid="add-wez-button"
                    aria-label="Add new WEZ card"
                >
                    <LuPlus /> New WEZ Card
                </Button>
            </Col>
        </Row>
        <div className={sectionStyles.container.className}>
            {getSection(schema, "WEZ_INFO", false)[0].Value !== "N/A" ? getSection(schema, "WEZ_INFO", false).reverse().map((w) => {
                return <Card
                    key={w.Id + w.Value}
                    data-testid={`wez-item-${w.Id}`}
                >
                    <Card.Header className="border-0 py-4">
                        <Row
                            className="align-items-center align-text-center"
                            style={{
                                fontWeight: 700
                            }}
                        >
                            <Col style={{ minWidth: "50%" }}>
                                <Row className="align-items-center align-text-center">
                                    <Col style={{ minWidth: "150px" }} className="py-2" aria-label="WEZ name">{w.Scheme}</Col>
                                    <Col style={{ minWidth: "80%" }}>
                                        <div style={{ maxWidth: "100%"}} className="py-2">{w.Value
                                            ? <>
                                                <Row className="mb-2">
                                                    <Col><div className="border rounded p-1" style={{ fontWeight: 500, overflowY: "auto", maxHeight: "110px", width: "100%", whiteSpace:"preserve-breaks"}}>
                                                        {wezInfoText(w)}</div></Col>
                                                </Row></>
                                            : <></>}
                                        </div>
                                        <div style={{ maxWidth: "100%", display: "inline-flex", textWrap: "nowrap" }} className="py-2"><div className="py-1 pr-1">Source:&nbsp;</div>
                                            {ignoreSource.findIndex(
                                                (s) => s.toLowerCase() === w.Source
                                            ) === -1
                                                ? <div className="border rounded p-1" style={{ fontWeight: 500, overflowX: "auto" }}>
                                                    {w.Source ? <Link to={w.Source} aria-label="Source link">{w.Source}</Link> : <span style={{color:"red"}} aria-label="Missing source warning">Missing Source</span>}</div>
                                                : <div className="p-1" style={{ fontWeight: 500, overflowX: "auto" }} aria-label="Ignored source">{w.Source}</div>}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{ minWidth: "175px" }}>
                                <Row className="align-items-center align-text-center">
                                    <Col style={{ minWidth: "55%", textWrap: "nowrap" }}>
                                        <Row>
                                            <Col style={{ minWidth: "50%", textWrap: "nowrap"  }} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>Updated</Col><Col style={{ minWidth:"175px", fontWeight:"500"}} aria-label="Last updated date">{(w.UpdatedAt)}</Col></Row></Col>
                                            <Col style={{ minWidth: "175px", textWrap: "nowrap"}} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>by User</Col><Col style={{ minWidth:"175px", fontWeight:"500"}} aria-label="Updated by user">{w.VerifiedBy}</Col></Row></Col>
                                        </Row>
                                    </Col>
                                    <Col style={{ textAlign: "center", maxWidth: "fit-content"}} className="px-4">
                                        <Button variant="danger"
                                            disabled={
                                                ignoreSource.findIndex((s) => s === w.Source) !== -1 || isDeleting
                                            }
                                            onClick={() => handleDelete(w.Id)}
                                            data-testid={`delete-wez-${w.Id}`}
                                            aria-label="Delete WEZ card">
                                            <LuTrash2 /> Delete
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Header>
                </Card>;
            }
            )
                : <Card><Card.Header className="border-bottom-0">No WEZ Data</Card.Header></Card>}
        </div>
    </>
    );
};

WEZSection.propTypes = {
    schema: PropTypes.array.isRequired,
    ignoreSource: PropTypes.array.isRequired,
    handleAddSchemaClick: PropTypes.func.isRequired
};

export default WEZSection;
