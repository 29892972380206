import React, { useState } from "react";
import { Modal, Container, Form, Row, Col, Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useUser } from "../contexts/user";
import { createSatRow } from "../utils/Api";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const ModalAddSatellite = ({ show, setShow }) => {
    const { username } = useUser();
    const [SatNo, setSatNo] = useState("");
    const [Value, setValue] = useState("");
    const [Source, setSource] = useState("");

    const darkTheme = createTheme({
        palette: {
            mode: "dark"
        }
    });

    const handleClose = () => setShow(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newSatellite = {
            SatNo: SatNo,
            VerifiedBy: username,
            Scheme: "Additional Names",
            Section: "MISSION_DETAILS",
            Source: Source,
            Value: Value,
            Timestamp: null
        };

        try {
            const response = await createSatRow(newSatellite);
            if (response.success === false) {
                toast.error(`Failed to create satellite: ${response.error}`);
            } else {
                setShow(false); // Close the modal on success
            }
        } catch (error) {
            toast.error(`Error creating satellite: ${error.message}`);
        }
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Satellite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={12}>
                                    <TextField
                                        fullWidth
                                        label="SatNo"
                                        variant="outlined"
                                        value={SatNo}
                                        onChange={(e) => {
                                            const value = parseInt(e.target.value, 10);
                                            if (value > 0 || isNaN(value)) {
                                                setSatNo(e.target.value);
                                            }
                                        }}
                                        required
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={12}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={Value}
                                        onChange={(e) => setValue(e.target.value)}
                                        required
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={12}>
                                    <TextField
                                        fullWidth
                                        label="Source"
                                        variant="outlined"
                                        rows={4}
                                        value={Source}
                                        onChange={(e) => setSource(e.target.value)}
                                        required
                                    />
                                </Col>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </ThemeProvider>
    );
};

ModalAddSatellite.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default ModalAddSatellite;
