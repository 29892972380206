import React, { useEffect, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import "../css/sat-modal.css";
import { LuPlus } from "react-icons/lu";
import ModalNewSchema from "./ModalNewSchema";
import ModalSchemaEntry from "./ModalSchemaEntry";
import PropTypes from "prop-types";
import { AccordionProvider } from "../contexts/AccordionContext";
import ImageSection from "./EditDataElements/ImageSection";
import WEZSection from "./EditDataElements/WEZSection";
import SectionAccordion from "./EditDataElements/SectionAccordion";

const sections = {
    "Summary": "SUMMARIES_AND_MISC",
    "Launch Details": "LAUNCH_DETAILS",
    "Mission Details": "MISSION_DETAILS",
    "Characteristics": "CHARACTERISTICS",
    "Frequencies & Comms": "FREQUENCIES_AND_COMMS",
    "Satellite Status": "SATELLITE_STATUS",
    "WEZ Info": "WEZ_INFO"
};

const ignoreSchemas = [
    "last_updated_at",
    "last_updated_by",
    "nominated_at",
    "nominated_by"
];

const ignoreSource = [
    "turion"
];

function ModalEditData({
    show,
    setShow,
    selectedSat,
    onSatUpdate
}) {
    const [showAddSchemaModal, setShowAddSchemaModal] = useState(false);
    const [showNewSchemaModal, setShowNewSchemaModal] = useState(false);
    const [selectedSchemeData, setSelectedSchemeData] = useState(null);
    const [selectedScheme, setSelectedScheme] = useState("");
    const [schema, setSchema] = useState([]);

    const handleClose = () => setShow(false);

    const handleAddSchemaClick = (schemeData = null, scheme = "") => {
        setSelectedSchemeData(schemeData);
        setSelectedScheme(scheme);
        setShowAddSchemaModal(true);
    };

    const handleAddSchema = async (newSchema) => {
        setSelectedSchemeData(newSchema);
        await onSatUpdate(selectedSat.SatNo);
    };

    useEffect(() => {
        if (!selectedSat?.Data) {return;}
        const filteredData = selectedSat.Data.filter((x) => {
            return !ignoreSchemas.includes(x.Scheme);
        });
        setSchema(filteredData);
    }, [selectedSat]);

    return (
        selectedSat && <>
            <ModalSchemaEntry
                sat={selectedSat}
                show={showAddSchemaModal}
                setShow={setShowAddSchemaModal}
                scheme={selectedScheme}
                schemeData={selectedSchemeData}
                onAddSchema={handleAddSchema}
            />
            <ModalNewSchema
                sat={selectedSat}
                show={showNewSchemaModal}
                setShow={setShowNewSchemaModal}
                onAddScheme={handleAddSchema}
            />
            <Modal
                className="sat-modal"
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        style={{
                            fontSize: "24px"
                        }}>
                            Editing <b>{selectedSat?.Name} - ({selectedSat?.SatNo})</b>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="mb-4">
                        <span>
                            <Button
                                style={{ marginRight: "10px" }}
                                variant="warning"
                                onClick={() => handleAddSchemaClick()}
                            > <LuPlus /> Attach Schema</Button>

                            <Button
                                variant="secondary"
                                onClick={() => setShowNewSchemaModal(!showNewSchemaModal)}
                            > <LuPlus /> Create New Schema</Button>
                        </span>
                    </Row>

                    {/* Data Start */}
                    <AccordionProvider onSatUpdate={onSatUpdate} satNo={selectedSat.SatNo}>
                        <ImageSection
                            schema={schema}
                            ignoreSource={ignoreSource}
                            handleAddSchemaClick={handleAddSchemaClick}
                        />

                        <SectionAccordion
                            sectionTitle="Summary"
                            section={sections["Summary"]}
                            schema={schema}
                            handleAddSchemaClick={handleAddSchemaClick}
                        />

                        <SectionAccordion
                            sectionTitle="Launch Details"
                            section={sections["Launch Details"]}
                            schema={schema}
                            handleAddSchemaClick={handleAddSchemaClick}
                        />

                        <SectionAccordion
                            sectionTitle="Mission Details"
                            section={sections["Mission Details"]}
                            schema={schema}
                            handleAddSchemaClick={handleAddSchemaClick}
                            valueDisplayFilter={(element) => {
                                if (element.Scheme === "Associated Satellites") {
                                    return element.Value
                                        .split(",")
                                        .map((s) => s.trim())
                                        .filter((s) => s !== "")
                                        .join(", ");
                                }
                                return element.Value;
                            }}
                        />

                        <SectionAccordion
                            sectionTitle="Characteristics"
                            section={sections["Characteristics"]}
                            schema={schema}
                            handleAddSchemaClick={handleAddSchemaClick}
                        />

                        <SectionAccordion
                            sectionTitle="Frequencies & Comms"
                            section={sections["Frequencies & Comms"]}
                            schema={schema}
                            handleAddSchemaClick={handleAddSchemaClick}
                        />

                        <SectionAccordion
                            sectionTitle="Satellite Status"
                            section={sections["Satellite Status"]}
                            schema={schema}
                            handleAddSchemaClick={handleAddSchemaClick}
                        />

                        <WEZSection
                            schema={schema}
                            ignoreSource={ignoreSource}
                            handleAddSchemaClick={handleAddSchemaClick}
                        />
                    </AccordionProvider>
                </Modal.Body>
            </Modal>
        </>
    );
}

ModalEditData.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    selectedSat: PropTypes.object,
    onSatUpdate: PropTypes.func.isRequired
};

export default ModalEditData;
