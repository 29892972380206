export const sectionStyles = {
    header: {
        title: {
            fontSize: "24px",
            color: "#ffc107",
            marginBottom: "1rem"
        }
    },
    container: {
        variant: "dark",
        className: "mb-4",
        backgroundColor: "#181b1f",
        borderRadius: "5px"
    }
};
