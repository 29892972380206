import React, {useState, useEffect} from "react";
import {Form} from "react-bootstrap";
import { TextField } from "@mui/material";
import "../css/poi.css";
import MenuItem from "@mui/material/MenuItem";
import { Wezs } from "../utils/constants";
import PropTypes from "prop-types";

export const WezForm = ({style={}, onChange, onTypeChange}) => {
    const [wezType, setWezType] = useState(Object.keys(Wezs)[0]);
    const [selectedPreset, setSelectedPreset] = useState(Object.keys(Wezs[wezType])[0]);
    const [formData, setFormData] = useState(Wezs[wezType][selectedPreset]);

    // Notify parent of changes
    useEffect(() => {
        onChange?.(formData);
    }, [formData, onChange]);

    const handleWezType = (e) => {
        const newType = e.target.value;
        setWezType(newType);
        // Notify parent of type change
        onTypeChange?.(newType);
        // Reset to first preset of new type and update form data
        const firstPreset = Object.keys(Wezs[newType])[0];
        setSelectedPreset(firstPreset);
        setFormData(Wezs[newType][firstPreset]);
    };

    const handlePresetChange = (e) => {
        const newPreset = e.target.value;
        setSelectedPreset(newPreset);
        // Reset form data to the selected preset's template
        setFormData({...Wezs[wezType][newPreset]});
    };

    const handleAttributeChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value
        }));
    };

    const defaultStyle = {
        width: "100%",
        ...style
    };

    return (
        <Form.Group className="mb-3">
            <div className="mb-3">
                <TextField
                    style={defaultStyle}
                    select
                    name="type"
                    label="WEZ Type"
                    value={wezType ?? ""}
                    onChange={handleWezType}
                    required
                >
                    {Object.keys(Wezs).map((key) => (
                        <MenuItem key={key} value={key}>
                            {key}
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            <div className="mb-3">
                <TextField
                    style={defaultStyle}
                    select
                    name="preset"
                    label="Preset Configuration"
                    value={selectedPreset}
                    onChange={handlePresetChange}
                    required
                >
                    {Object.keys(Wezs[wezType]).map((preset) => (
                        <MenuItem key={preset} value={preset}>
                            {preset}
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            {selectedPreset === "Custom" ? (
                <>
                    {Object.entries(Wezs[wezType]["Custom"]).map(([field]) => (
                        <div key={field} className="mb-3">
                            <TextField
                                style={defaultStyle}
                                label={field}
                                value={formData[field]}
                                onChange={(e) => handleAttributeChange(field, e.target.value)}
                                required
                            />
                        </div>
                    ))}
                </>
            ) : (
                <div className="mb-3">
                    <TextField
                        style={defaultStyle}
                        multiline
                        label="Current Values"
                        value={Object.entries(formData)
                            .map(([key, value]) => `${key}: ${value}`)
                            .join("\n")}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                </div>
            )}
        </Form.Group>
    );
};

WezForm.propTypes = {
    style: PropTypes.object,
    onChange: PropTypes.func,
    onTypeChange: PropTypes.func
};
