import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Modal, Container, Form, Row, Col, Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import { useUser } from "../contexts/user";
import { createSatRow } from "../utils/Api";
import Autocomplete from "@mui/material/Autocomplete";
import "../css/poi.css";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const ModalNewSchema = ({
    show,
    setShow,
    sat,
    onAddScheme
}) => {
    const {username} = useUser();
    const [saving, setSaving] = useState(false);
    const [selectedSection, setSelectedSection] = useState(null);
    const [saveStatus, setSaveStatus] = useState(<></>);

    const dossierSections = [
        "LAUNCH_DETAILS",
        "MISSION_DETAILS",
        "CHARACTERISTICS",
        "FREQUENCIES_AND_COMMS",
        "SATELLITE_STATUS"
    ];

    const darkTheme = createTheme({
        palette: {
            mode: "dark"
        }
    });

    const handleClose = () => {
        setShow(false);
    };

    const handleSection = (_, newValue) => {
        setSelectedSection(newValue);
    };

    const isValidUrl = (urlString) => {
        try {
            const url = new URL(urlString);
            return url.protocol === "http:" || url.protocol === "https:";
        } catch {
            return false;
        }
    };

    const handleSubmit = async (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const scheme = data.get("newSchemaName");
        const source = data.get("source");
        const value = data.get("value");

        if (!isValidUrl(source)) {
            setSaveStatus(<div style={{color:"red"}}>Please enter a valid URL starting with http:// or https://</div>);
            return;
        }

        if(!selectedSection || !source || !value){
            return;
        }
        setSaving(true);

        const newSchema = {
            SatNo: JSON.stringify(sat.SatNo),
            Scheme: scheme,
            Section: selectedSection,
            Source: source,
            Value: value,
            VerifiedBy: username
        };
        try {
            await createSatRow(newSchema);
            setSaving(false);
            onAddScheme(newSchema);
        } catch(e) {
            toast.error(`Failed to create entry: ${e.message}`);
        }
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Modal
                className="newSchemaModal"
                show={show}
                onHide={handleClose}
                style={{ background: "#000000c7" }}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Schema
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ padding: 0, overflow: "hidden" }}>
                    <Container>
                        <Row className="pt-4">
                            <Col>
                                <Form id="poiForm" onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="poi">
                                        <Form.Label>Schema Name</Form.Label>
                                        <TextField
                                            className="mb-2"
                                            style={{ width: "100%", color: "white", borderColor: "white" }}
                                            name="newSchemaName"
                                            type="text"
                                            required
                                            inputProps={{
                                                "data-testid": "schema-name-input"
                                            }}
                                        />

                                        <Form.Label>Which section of the dossier?</Form.Label>
                                        <Autocomplete
                                            className="mb-2"
                                            ListboxProps={{ style: { maxHeight: "8rem" } }}
                                            disablePortal
                                            id="schemaCombo"
                                            name="section"
                                            value={selectedSection}
                                            options={dossierSections}
                                            onChange={handleSection}
                                            required
                                            isOptionEqualToValue={(option, value) => {
                                                if (value === null || value === "") {
                                                    return true;
                                                }
                                                return option === value;
                                            }}
                                            renderInput={(params) => <TextField name="scheme" {...params} required inputProps={{ ...params.inputProps, "data-testid": "section-input" }} />}
                                        />

                                        <div style={{ height: "1px", backgroundColor: "white", margin: "20px 0" }}></div>

                                        <span><b>Add the value and source information</b></span>
                                        <br />

                                        <Form.Label>Source</Form.Label>
                                        <TextField
                                            className="mb-2"
                                            style={{ width: "100%" }}
                                            name="source"
                                            type="url"
                                            placeholder="URL of where you found this information"
                                            multiline
                                            required
                                            inputProps={{
                                                pattern: "https?://.+",
                                                title: "Please enter a valid URL starting with http:// or https://",
                                                "data-testid": "source-input"
                                            }}
                                        />

                                        <Form.Label>Value</Form.Label>
                                        <TextField
                                            className="mb-2"
                                            minRows={3}
                                            style={{ width: "100%" }}
                                            name="value"
                                            type="text"
                                            placeholder="Related data and information"
                                            multiline
                                            required
                                            inputProps={{
                                                "data-testid": "value-input"
                                            }}
                                        />

                                        <div className="pb-2">
                                            <br />
                                            Verified by: <b>{username}</b>
                                        </div>
                                        <br />
                                        <Button
                                            type="submit"
                                            disabled={saving}
                                            variant="warning"
                                            style={{
                                                width: "150px"
                                            }}
                                        >
                                            {saving ? "Saving..." : "Save"}
                                        </Button>
                                        {saveStatus}
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </ThemeProvider>
    );
};

ModalNewSchema.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    sat: PropTypes.object,
    onAddScheme: PropTypes.func.isRequired
};

export default ModalNewSchema;
