import React, { useState, useEffect, lazy, Suspense, memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, Col, Row, Button, Image, OverlayTrigger, Tooltip, Figure, FigureImage, FigureCaption } from "react-bootstrap";
import "../css/cards.css";
import "../css/sat-modal.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import OverlayText from "./OverlayText";
import { getFlagLink, timeDifference, isGeo, getScheme, getImages, getStatus, getRegime } from "../utils/Utils";
import { useUser } from "../contexts/user";
import { sortBy } from "lodash";
import { imgPlaceholderPath } from "../utils/constants";
import { toast } from "react-toastify";
//icons
import { GiChart } from "react-icons/gi";
import { LuClipboardCopy } from "react-icons/lu";
import { FaFolder } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import PropTypes from "prop-types";
// Import the Carousel component lazily
const LazyCarousel = lazy(() => import("react-responsive-carousel").then((module) => ({
    default: module.Carousel
})));

const SatelliteCard = ({
    setShowSatModal,
    sat,
    setSelectedSat
}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { isLoggedIn } = useUser();
    const [showImage, setShowImage] = useState(true);
    const [maxCols, setMaxCols] = useState(3);

    const isHomePage = location.pathname === "/";

    const clickModalBtn = (sat) => {
        setShowSatModal(true);
        setSelectedSat(sat);
    };

    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            alert(`Link Copied:\n ${text}`);
        } catch (error) {
            alert("Failed to Copy Link.");
            toast.error("Failed to copy link to clipboard");
        }
    };

    const openInNewTab = () => {
        return window.open(`/dossier/${sat?.SatNo}`, "_blank", "noopener,noreferrer");
    };

    const handleClick = () => navigate(`/dossier/${sat?.SatNo}`);

    const hideImage = () => {
        setShowImage(false);
    };

    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip">
            {text}
        </Tooltip>
    );

    const getName = (sat) => {
        const scheme = getScheme(sat.Data, ["Additional Names"]);
        if (scheme?.[0].Value === "N/A") {return "";}
        let names = scheme.map((s) => s.Value);
        names = [...new Set(names)];
        return "Alias: " + names.join(",");
    };

    /// Sorts schema by UpdatedAt column
    const sortSchema = (unsortSchema) => {
        return sortBy(unsortSchema,Date.parse(unsortSchema.UpdatedAt)).reverse();
    };

    useEffect(() => {
        setMaxCols(isLoggedIn && sat?.HrrRank ? 3 : 4);
    }, [isLoggedIn, sat?.HrrRank]);

    const isNotDossierPage = !location.pathname.includes("dossier");

    const openSpacefana = () => {
        const baseUrl = (() => {
            const hostname = window.location.hostname;
            return (hostname === "localhost" || hostname === "saberdevusa.xyz")
                ? "spacefana.saberdevusa.xyz"
                : "spacefana.saberastro.com";
        })();
        const src = `https://${baseUrl}/d/PO81TfmVk/elsethistory?orgId=1&var-satnos=${sat.SatNo}&var-udlp=&var-showSourceColor=Yes&var-lines=Yes&var-accesstoken=&var-stdDev=3&var-showWindu=Yes&from=1715977837024&to=1716582637025&panelId=2`;
        window.open(src, "_blank", "noopener,noreferrer");
    };

    return (
        <>
            <Card
                style={{
                    backgroundColor: "#181b1f",
                    color: "white",
                    height: (setShowSatModal ? "100%" : ""),
                    borderRadius: "5px"
                }}
            >

                <Suspense fallback={<div className="loading-placeholder">Loading images...</div>}>
                    <LazyCarousel
                        showThumbs={false}
                        showIndicators={false}
                    >
                        {getImages(sat).length === 0 ? (
                            <Figure className="mb-0">
                                <FigureImage
                                    className="mb-0"
                                    style={{
                                        width: "100%",
                                        height: isHomePage ? "275px" : "auto",
                                        objectFit: "contain",
                                        minHeight: "200px",
                                        maxHeight: "50vh"
                                    }}
                                    src={imgPlaceholderPath}
                                    alt={sat?.Name}
                                />
                            </Figure>
                        ) : (
                            getImages(sat).map((images) =>
                                <Figure className="mb-0" key={images.Value}>
                                    <FigureImage
                                        className="mb-0"
                                        style={{
                                            width: "100%",
                                            height: isHomePage ? "275px" : "auto",
                                            objectFit: "contain",
                                            minHeight: "200px",
                                            maxHeight: "50vh"
                                        }}
                                        src={images.Value.startsWith("http://") || images.Value.startsWith("https://") ? images.Value : imgPlaceholderPath}
                                        alt={sat?.Name}
                                    />
                                    {images.Source &&
                                        <FigureCaption className='pb-2 mt-2 border-bottom rounded-3'>
                                            <OverlayText text={"Source: "+ images.Source} maxLength={40} />
                                            <Button
                                                className='btn-copy'
                                                title='Copy Link'
                                                style={{ paddingTop: "0", paddingBottom: "0", marginLeft: "5px", marginRight: "auto" }}
                                                onClick={() => handleCopy(images.Source)}>
                                                <LuClipboardCopy />
                                            </Button>
                                        </FigureCaption>
                                    }
                                </Figure>
                            )
                        )}
                    </LazyCarousel>
                </Suspense>

                {showImage && (
                    <Image
                        style={{
                            width: "50px",
                            height: "50px",
                            position: "absolute",
                            zindex: 9999,
                            top: 0,
                            left: 5
                        }}
                        alt={sat?.CountryId}
                        src={getFlagLink(sat)}
                        onError={hideImage}
                    />
                )}

                <Card.Body>

                    <Card.Title className="card-text bold">
                        ({sat?.SatNo}) {sat?.Name}
                    </Card.Title>
                    <div style={{ fontSize: "12px", marginBottom: "10px" }}>
                        {getName(sat)}
                    </div>
                    <div className="card-text">

                        <Row className="bold">
                            <Col xs={4}>Last Seen</Col>
                            {isGeo(sat) && (
                                <>
                                    <Col xs={4}>Lon (°E)</Col>
                                    <Col xs={4}>Drift (°E/d)</Col>
                                </>
                            )}
                            {!isGeo(sat) && (
                                <>
                                    <Col xs={4}>Inc (°)</Col>
                                    <Col xs={4}>Raan (°)</Col>
                                </>
                            )}
                        </Row>
                        <Row>
                            <Col xs={4}>{timeDifference(sat?.Epoch)}</Col>
                            {isGeo(sat) && (
                                <>
                                    <Col xs={4}>{sat?.Longitude}</Col>
                                    <Col xs={4}>{sat?.LonDriftDegreesPerDay}</Col>
                                </>
                            )}
                            {!isGeo(sat) && (
                                <>
                                    <Col xs={4}>{sat?.Inclination}</Col>
                                    <Col xs={4}>{sat?.Raan}</Col>
                                </>
                            )}
                        </Row>

                        <br />
                        <Row className="bold">
                            {isLoggedIn && sat?.HrrRank && (
                                <Col xs={2}>Rank</Col>
                            )}
                            <Col xs={maxCols}>Regime</Col>
                            <Col xs={maxCols}>Type</Col>
                            <Col xs={maxCols}>Status</Col>
                        </Row>
                        <Row>
                            {isLoggedIn && sat?.HrrRank && (
                                <Col xs={2}>{sat?.HrrRank}</Col>
                            )}
                            <Col xs={maxCols}>
                                <OverlayText text={getRegime(sat).toLowerCase()} maxLength={8} />
                            </Col>
                            <Col xs={maxCols}>
                                <OverlayText text={sat?.ObjectType ? sat?.ObjectType.toLowerCase() : ""} maxLength={8} />
                            </Col>
                            <Col xs={isLoggedIn && sat?.HrrRank ? 3 : 4}>
                                <OverlayText text={getStatus(sat)} maxLength={8} />
                            </Col>
                        </Row>

                    </div>
                    <br />
                    {isNotDossierPage && (
                        <OverlayText
                            text={sortSchema(getScheme(sat?.Data, ["Overall Summary"]))[0].Value}
                            maxLength={125}
                        />
                    )}

                </Card.Body>

                {setShowSatModal ? (<Card.Footer>
                    <Row className="text-center">
                        <Col xs={6}>
                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip("View Dossier (right-click to open in new tab)")}
                            >
                                <Button
                                    variant="dark"
                                    style={{ color: "rgb(235 215 147)" }}
                                    onClick={handleClick}
                                    onContextMenu={openInNewTab}
                                    disabled={sat.Data.length === 0}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <FaFolder style={{ marginBottom: "2px" }} />
                                        <span style={{ color: "white", lineHeight: "1.5" }}>&nbsp;Dossier</span>
                                    </div>
                                </Button>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={2}>
                            {isLoggedIn && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip("Edit Data")}
                                >
                                    <Button variant="dark" onClick={() => { clickModalBtn(sat); }} aria-label="Edit Data">
                                        <FaRegEdit />
                                    </Button>
                                </OverlayTrigger>
                            )}
                        </Col>
                        <Col xs={4}>
                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip("View Elset History")}
                            >
                                <Button variant="dark" onClick={openSpacefana} aria-label="View Elset History">
                                    <GiChart />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Card.Footer>) : (<></>)}

            </Card>
        </>
    );
};

SatelliteCard.propTypes = {
    setShowSatModal: PropTypes.func,
    sat: PropTypes.shape({
        SatNo: PropTypes.number.isRequired,
        Name: PropTypes.string.isRequired,
        CountryId: PropTypes.string,
        Epoch: PropTypes.string,
        Longitude: PropTypes.number,
        LonDriftDegreesPerDay: PropTypes.number,
        Inclination: PropTypes.number,
        Raan: PropTypes.number,
        ObjectType: PropTypes.string,
        HrrRank: PropTypes.number,
        Data: PropTypes.array.isRequired
    }).isRequired,
    setSelectedSat: PropTypes.func
};

export default memo(SatelliteCard);
