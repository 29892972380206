import React from "react";
import {Image} from "react-bootstrap";
import { Grid } from "@mui/material";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import nathan from "../assets/team/nathan-parrott.png";
import connor from "../assets/team/connor-okeefe.png";
import craig from "../assets/team/craig-robinson.png";
import josh from "../assets/team/joshua-letcher.png";
import justin from "../assets/team/justin-law.png";
import pieter from "../assets/team/pieter-rombauts.png";
import preetham from "../assets/team/preetham-akula.png";
import sai from "../assets/team/sai-vallpureddy.png";
import sam from "../assets/team/samuel-carbone.png";
import zach from "../assets/team/zach-archer.png";

const TeamMembers = [
    {
        Name: "Nathan Parrott",
        Role: "Developer",
        Img: nathan,
        Link: "https://www.linkedin.com/in/nathan-parrott-%F0%9F%9A%80-5a459b41/"
    },
    {
        Name: "Connor Okeefe",
        Role: "Researcher",
        Img: connor,
        Link: "https://www.linkedin.com/in/connorbokeefe/"
    },
    {
        Name: "Craig Robinson",
        Role: "Developer",
        Img: craig,
        Link: "https://www.linkedin.com/in/craig-robinson-space/"
    },
    {
        Name: "Pieter Rombauts",
        Role: "Developer",
        Img: pieter,
        Link: "https://www.linkedin.com/in/pieterrombauts/"
    },
    {
        Name: "Joshua Letcher",
        Role: "Researcher",
        Img: josh,
        Link: "https://www.linkedin.com/in/joshualetcher/"
    },
    {
        Name: "Sai Vallpureddy",
        Role: "Researcher",
        Img: sai,
        Link: "https://www.linkedin.com/in/arcskyrider/"
    },
    {
        Name: "Samuel Carbone",
        Role: "Researcher",
        Img: sam,
        Link: "https://www.linkedin.com/in/samuel-carbone-100a47168/"
    },
    {
        Name: "Preetham Akula",
        Role: "Researcher",
        Img: preetham,
        Link: "https://www.linkedin.com/in/preetham-akula/"
    },
    {
        Name: "Justin Law",
        Role: "Developer",
        Img: justin,
        Link: "https://www.linkedin.com/in/justinwingchunglaw/"
    },
    {
        Name: "Zach Archer",
        Role: "Developer",
        Img: zach,
        Link: "https://github.com/AFKrcher"
    }
];

const About = () => {
    return (
        <div>
            <Helmet>
                <title>About PROBE</title>
            </Helmet>
            <Container
                style={{
                    paddingTop:"25px"
                }}>
                <h1>About <span className="yellow">PROBE</span></h1>
                <p>
                    <span className="yellow">P</span>ublicly <span className="yellow">R</span>esearched <span className="yellow">O</span>bservatory (<span className="yellow">PROBE</span>)
            is a system designed to archive comprehensive dossiers on satellites.
            Its inception stemmed from the necessity to offer a straightforward and intuitive approach
            to the visualization of satellite-related information. PROBE enables a
            decentralized network of professionals to continuously maintain and update this data efficiently.
                </p>
                <br/>

                <h2>Meet the team</h2>
                <p>
            Click on our profile pictures to connect with us! If you have questions
            or issues, please see the footer at the bottom of this page.
                </p>
                <br/>

                <Grid container spacing={2} className="avatars">
                    {TeamMembers.map((member, index) => {
                        return (
                            <Grid item xs key={index}>

                                <a
                                    href={member.Link}
                                    id={`image-${index}`}
                                    target="_blank"
                                    key={index}
                                    style={{
                                        textDecoration: "none",
                                        color: "white"
                                    }}
                                    rel="noreferrer"
                                >
                                    <Image
                                        style={{
                                            display: "block",
                                            objectFit: "cover",
                                            verticalAlign: "middle",
                                            width: "230px",
                                            height: "230px",
                                            border: "2.5px solid white",
                                            borderRadius: 5
                                        }}
                                        src={member.Img}
                                        alt={`${member.Name}`}
                                    />
                                    <p>
                                        {member.Name}
                                    </p>
                                    <p style={{fontSize:"1rem"}}>
                                        {member.Role}
                                    </p>
                                </a>

                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </div>
    );
};

export default About;
