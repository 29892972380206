export const REGIMES = Object.freeze({
    1: "Undetermined",
    2: "Leo",
    4: "Heo",
    8: "GeoInclined",
    16: "Meo",
    32: "Molniya",
    64: "Sso",
    128: "Polar",
    256: "GeoStationary",
    512: "Graveyard"
});

export const SPACE_COCKPIT_BASE_URL = "https://spacecockpit.saberastro.com/";

export const imgPlaceholderPath = require("../assets/images/sat_placeholder.jpg");

export const Wezs = Object.freeze({
    "Jammer": Object.freeze({
        "Custom": Object.freeze({"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""})
    }),
    "Laser": Object.freeze({
        "Custom": Object.freeze({"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""})
    }),
    "Lidar": Object.freeze({
        "Custom": Object.freeze({"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""})
    }),
    "Net": Object.freeze({
        "Custom": Object.freeze({"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""})
    }),
    "Armature": Object.freeze({
        "Custom": Object.freeze({"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""})
    }),
    "Projectile": Object.freeze({
        "Custom": Object.freeze({"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""})
    }),
    "Radar": Object.freeze({
        "Custom": Object.freeze({"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""})
    }),
    "Sprayer": Object.freeze({
        "Custom": Object.freeze({"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""})
    }),
    "Telescope": Object.freeze({
        "Custom": Object.freeze({"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""})
    }),
    "Microwave": Object.freeze({
        "Custom": Object.freeze({"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""})
    }),
    "Explosive": Object.freeze({
        "Custom": Object.freeze({"Description":"", "Min Range (km)": 0, "Max Range (km)": 0, "Additional Details": ""})
    })
});
