import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useUser } from "../contexts/user";
import PropTypes from "prop-types";
import {
    ParameterSelect,
    CountryIdForm,
    ObjectTypeForm,
    RegimeForm,
    RankForm,
    OperatorValueForm,
    VALIDATION_RULES
} from "../utils/AddFilterForms";

const SearchModal = ({
    show,
    setClose,
    addFilter
}) => {
    const { isLoggedIn } = useUser();
    const [parameter, setParameter] = useState("--");
    const [operator, setOperator] = useState("=");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState(null);

    const handleAddFilter = () => {
        if (parameter && parameter !== "--") {
            const newFilter = {
                [parameter]: {
                    param: parameter,
                    operator: operator,
                    start: start.toString(),
                    end: operator === "range" ? end.toString() : null
                }
            };
            addFilter(newFilter);
            handleClose();
        } else {
            alert("Please select a valid parameter before adding a filter.");
        }
    };

    const handleClose = () => {
        setClose(false);
        setParameter("--");
        setOperator("=");
        setStart("");
        setEnd(null);
    };

    const getForm = () => {
        if (VALIDATION_RULES[parameter]) {
            return (
                <div data-testid={`${parameter}-form`}>
                    <OperatorValueForm
                        parameter={parameter}
                        operator={operator}
                        setOperator={setOperator}
                        start={start}
                        setStart={setStart}
                        end={end}
                        setEnd={setEnd}
                    />
                </div>
            );
        }

        switch (parameter) {
        case "CountryId":
            return <div data-testid="CountryId-form"><CountryIdForm start={start} setStart={setStart} /></div>;
        case "ObjectType":
            return <div data-testid="ObjectType-form"><ObjectTypeForm start={start} setStart={setStart} /></div>;
        case "Regime":
            return <div data-testid="Regime-form"><RegimeForm start={start} setStart={setStart} /></div>;
        case "Rank":
            return <div data-testid="Rank-form"><RankForm start={start} setStart={setStart} /></div>;
        default:
            return null;
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Apply Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Parameter</Form.Label>
                        <ParameterSelect parameter={parameter} setParameter={setParameter} isLoggedIn={isLoggedIn} />
                    </Form.Group>

                    {getForm()}

                    <br />

                    <div className="d-flex justify-content-between">
                        <Button variant="primary" onClick={handleAddFilter}>
                            Add Filter
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

SearchModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setClose: PropTypes.func.isRequired,
    addFilter: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    removeFilter: PropTypes.func.isRequired
};

export default SearchModal;
