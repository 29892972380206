export const schemaTable = Object.freeze({
    "Bus": Object.freeze({
        section: "CHARACTERISTICS",
        description: "Name of bus",
        rows: 1
    }),
    "Expected Life": Object.freeze({
        section: "MISSION_DETAILS",
        description: "Expected life of the satellite in years",
        rows: 1
    }),
    "Comms Description": Object.freeze({
        section: "SUMMARIES_AND_MISC",
        description: "Brief paragraph about frequencies and communications. Include info about military usage if applicable.",
        rows: 3
    }),
    "Details Description": Object.freeze({
        section: "SUMMARIES_AND_MISC",
        description: "Brief paragraph about mission details such as purpose and goals. Include relevant information about military usage and if mission involves other satellites.",
        rows: 3
    }),
    "Frequency Band": Object.freeze({
        section: "FREQUENCIES_AND_COMMS",
        description: "Frequency band(s) of the satellite (ex. X-band).",
        rows: 1
    }),
    "ITU Name": Object.freeze({
        section: "MISSION_DETAILS",
        description: "International Telecommunication Union name of satellite. Sometimes called COSPAR ID, NSSDCA, or Intl Designator. Note the format required when entering data.",
        rows: 1
    }),
    "Launch Date": Object.freeze({
        section: "LAUNCH_DETAILS",
        description: "Date of launch - Date and time in UTC. If you are unsure about time, use 00:00:00.",
        rows: 1
    }),
    "Launch Mass": Object.freeze({
        section: "LAUNCH_DETAILS",
        description: "Launch mass of the satellite in kg. Do not include payload mass.",
        rows: 1
    }),
    "Launch Vehicle": Object.freeze({
        section: "LAUNCH_DETAILS",
        description: "Usually the name of rocket body that satellite is launched on such as Long March 3B or Falcon 9.",
        rows: 1
    }),
    "Additional Names": Object.freeze({
        section: "MISSION_DETAILS",
        description: "Additional names of the satellite (such as spelling or language variations).",
        rows: 1
    }),
    "Operator": Object.freeze({
        section: "MISSION_DETAILS",
        description: "Usually the company that built the satellite or the government agency that launched it.",
        rows: 1
    }),
    "Overall Summary": Object.freeze({
        section: "SUMMARIES_AND_MISC",
        description: "Summary of the satellite and mission, where it's from, type, purpose, alternate names, etc. Include associated satellites if applicable.",
        rows: 3
    }),
    "Owner": Object.freeze({
        section: "MISSION_DETAILS",
        description: "Usually country or company that launched the satellite",
        rows: 1
    }),
    "Polarization": Object.freeze({
        section: "FREQUENCIES_AND_COMMS",
        description: "Polarization of the satellite (C m-2).",
        rows: 1
    }),
    "Primary Mission": Object.freeze({
        section: "MISSION_DETAILS",
        description: "Primary purpose of the satellite (e.g. communications, surveillance, etc.). Military missions are usually prioritized.",
        rows: 2
    }),
    "Propulsion": Object.freeze({
        section: "CHARACTERISTICS",
        description: "Type of propulsion system used on the satellite such as chemical, solar, or electric.",
        rows: 1
    }),
    "RCS Body Dimension": Object.freeze({
        section: "CHARACTERISTICS",
        description: "RCS body dimension of the satellite in meters squared. Also include descriptors such as'large' or 'small' if applicable.",
        rows: 1
    }),
    "Size": Object.freeze({
        section: "CHARACTERISTICS",
        description: "Size of the satellite in meters. Specify dimensions such as length, width, or height (if applicable).",
        rows: 1
    }),
    "Status Description": Object.freeze({
        section: "SUMMARIES_AND_MISC",
        description: "Description of the satellite's current status (pre-set options).",
        rows: 1
    }),
    "TTC Downlink Frequencies": Object.freeze({
        section: "FREQUENCIES_AND_COMMS",
        description: "Telemetry, Tracking, and Command (TTC) downlink frequencies of the satellite. Can be S-band or specified frequencies (in Ghz).",
        rows: 1
    }),
    "TTC Uplink Frequencies": Object.freeze({
        section: "FREQUENCIES_AND_COMMS",
        description: "Telemetry, Tracking, and Command (TTC) uplink frequencies of the satellite. Can be S-band or specified frequencies (in Ghz).",
        rows: 1
    }),
    "Image": Object.freeze({
        section: "SUMMARIES_AND_MISC",
        description: "Image of the satellite or launch vehicle. Value should be the URL of the image and source should be where the image was found. Note that gifs ARE supported.",
        rows: 1
    }),
    "Payload": Object.freeze({
        section: "CHARACTERISTICS",
        description: "Type of payload on the satellite (ex. Sensor).",
        rows: 1
    }),
    "Launch History for Similar": Object.freeze({
        section: "SUMMARIES_AND_MISC",
        description: "Launch history for similar satellites (few sentences).",
        rows: 2
    }),
    "Frequency": Object.freeze({
        section: "FREQUENCIES_AND_COMMS",
        description: "Frequency of the sensor in Hz or GHz / or band.",
        rows: 1
    }),
    "Secondary Mission": Object.freeze({
        section: "MISSION_DETAILS",
        description: "Secondary purpose of the satellite (e.g. communications, surveillance, etc.).",
        rows: 2
    }),
    "Decay Date": Object.freeze({
        section: "LAUNCH_DETAILS",
        description: "Date when the satellite decayed or is expected to decay from orbit (YYYY-MM-DD)",
        rows: 1
    }),
    "WEZ": Object.freeze({
        section: "WEZ_INFO",
        description: "Weapon engagement zone details",
        rows: 1
    }),
    "Launch Site": Object.freeze({
        section: "LAUNCH_DETAILS",
        description: "Location where the satellite was launched from",
        rows: 1
    }),
    "Manufacturer": Object.freeze({
        section: "CHARACTERISTICS",
        description: "Company or organization that manufactured the satellite",
        rows: 1
    }),
    "Power": Object.freeze({
        section: "CHARACTERISTICS",
        description: "Power specifications and generation capabilities",
        rows: 1
    }),
    "Military Usage": Object.freeze({
        section: "MISSION_DETAILS",
        description: "Whether the satellite has military applications or not. If both military and civilian, select military.",
        rows: 1
    }),
    "Associated Satellites": Object.freeze({
        section: "SUMMARIES_AND_MISC",
        description: "Associated satellites (comma-separated list of satellite numbers)",
        rows: 1
    })
});
