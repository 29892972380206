import React from "react";
const Footer = () => {
    return (
        <div
            className="text-center p-3"
        >
        © 2023 Saber Astronautics
        </div>
    );
};

export default Footer;
